import * as React from 'react';
import { GlobalLoader } from 'components/globalLoader';

export const GlobalLoaderContext = React.createContext<{
  showGlobalLoader: (message?: string) => void;
  hideGlobalLoader: () => void;
}>({
  showGlobalLoader: (_message: string) => {},
  hideGlobalLoader: () => {}
});

export const GlobalLoaderProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [globalLoaderSetting, setGlobalLoaderSetting] = React.useState<{
    visible: boolean;
    message?: string;
  }>({
    visible: false
  });

  const contextValue = React.useMemo(
    () => ({
      showGlobalLoader: (message?: string) => {
        setGlobalLoaderSetting({
          visible: true,
          message
        });
      },
      hideGlobalLoader: () => {
        setGlobalLoaderSetting({
          visible: false
        });
      }
    }),
    [setGlobalLoaderSetting]
  );

  return (
    <GlobalLoaderContext.Provider value={contextValue}>
      <GlobalLoader
        open={globalLoaderSetting.visible}
        message={globalLoaderSetting.message}
      />
      {children}
    </GlobalLoaderContext.Provider>
  );
};
