import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { store } from './configureStore';
import mainTheme from './theme';
import history from './appHistory';
import ErrorBoundary from './errorBoundary';

import NotFound from 'components/pages/errors/404';

import {
  AdminRoute,
  AuthProvider,
  NehanAdminRoute,
  NotAuthedRoute,
  PrivateRoute,
  ProtectedRoute
} from 'components/route';
import { GenerateId } from 'components/diagram/utils';
import Cookies from 'universal-cookie';
import { clientIdName } from 'libs/callApi';

import { ConfirmationServiceProvider } from 'components/dialogs/ConfirmationService';
import { RetryLazy } from 'libs/retry';

import 'css/onboarding.css';
import 'react-tabulator/lib/styles.css';
import 'css/tabulator_materialize.min.css';

// Loaded
import InfoPage from 'components/pages/info';
import ManualPage from 'components/pages/manual';
import ProjectList from 'components/pages/projectList';
import DatasourceList from 'components/pages/datasourceList';
import ConnectionList from 'components/pages/connectionList';
import ExportListPage from 'components/pages/exportList';
import VariableListPage from 'components/pages/variableList';
import ReportListPage from 'components/pages/reportList';
import BuilderListPage from 'components/pages/builderList';
import ScheduleListPage from 'components/pages/schedules';
import NotificationDstList from 'components/pages/notification/destinationList';
import NotificationList from 'components/pages/notification/notificationList';
import NewDatasourceListPage from 'components/pages/newDatasourceList';
import NewConnectionListPage from 'components/pages/newConnectionList';
import AdminUserGroupListPage from 'components/pages/admin/adminUserGroupList';
import { GlobalLoaderProvider } from 'contexts/globalLoaderContext';

// Lazy Load
const Login = RetryLazy(() => import('components/pages/login'));
const EditUser = RetryLazy(() => import('components/pages/editUser'));
const NewDatasourceV2 = RetryLazy(
  () => import('components/pages/newDatasourceV2')
);
const NewDatasourceV3 = RetryLazy(
  () => import('components/pages/newDatasourceV3')
);
const EditDatasourceV2 = RetryLazy(
  () => import('components/pages/editDatasourceV2')
);
const EditDatasourceV3 = RetryLazy(
  () => import('components/pages/editDatasourceV3')
);

const NewConnectionPage = RetryLazy(
  () => import('components/pages/newConnection')
);
const EditConnectionPage = RetryLazy(
  () => import('components/pages/editConnection')
);

const ProjectView = RetryLazy(() => import('containers/projectViewContainer'));
const VisualizeViewV3 = RetryLazy(
  () => import('components/visualize/visualizeViewV3')
);
const Supplement = RetryLazy(() => import('components/supplement/supplement'));
const NewVariable = RetryLazy(() => import('components/pages/newVariable'));
const EditVariable = RetryLazy(() => import('components/pages/editVariable'));
const ReportView = RetryLazy(() => import('components/report/reportView'));
const ReportLogin = RetryLazy(() => import('components/report/reportLogin'));
const ReportPreview = RetryLazy(
  () => import('components/report/preview/reportView')
);

const NewBuilderPage = RetryLazy(() => import('components/pages/newBuilder'));
const BuilderView = RetryLazy(() => import('containers/builderViewContainer'));
const EditBuilderConnectionPage = RetryLazy(
  () => import('components/pages/editBuilderConnection')
);
const ScheduleEditPage = RetryLazy(
  () => import('components/pages/scheduleEdit')
);
const NotificationEditPage = RetryLazy(
  () => import('components/notification/notificationEdit/index')
);
const OauthCallback = RetryLazy(() => import('components/oauthCallback'));
const EditNotificationDst = RetryLazy(
  () => import('components/pages/notification/editDestination')
);
const NotificationDstSelectType = RetryLazy(
  () => import('components/pages/notification/destinationSelectType')
);

// Admin Pages
const AdminEditPaymentPage = RetryLazy(
  () => import('components/pages/admin/adminEditPayment')
);
const AdminProjectListPage = RetryLazy(
  () => import('components/pages/admin/adminProjectList')
);
const AdminDatasourceListPage = RetryLazy(
  () => import('components/pages/admin/adminDatasourceList')
);
const AdminBuilderListPage = RetryLazy(
  () => import('components/pages/admin/adminBuilderList')
);
const AdminConnectionListPage = RetryLazy(
  () => import('components/pages/admin/adminConnectionList')
);
const AdminVariableListPage = RetryLazy(
  () => import('components/pages/admin/adminVariableList')
);
const AdminReportListPage = RetryLazy(
  () => import('components/pages/admin/adminReportList')
);
const AdminScheduleListPage = RetryLazy(
  () => import('components/pages/admin/adminScheduleList')
);
const AdminNotificationListPage = RetryLazy(
  () => import('components/pages/admin/adminNotificationList')
);
const AdminNotificationDstsListPage = RetryLazy(
  () => import('components/pages/admin/adminDestinationList')
);
const AdminUserListPage = RetryLazy(
  () => import('components/pages/admin/adminUserList')
);

const AdminOrganizationListPage = RetryLazy(
  () => import('components/pages/admin/adminOrganizationList')
);
const AdminStorageUsage = RetryLazy(
  () => import('components/pages/admin/adminStorageUsage')
);
const AdminAuditLogPage = RetryLazy(
  () => import('components/pages/admin/adminAuditLog')
);

// Nehan Admin Pages
const AdminProjectExport = RetryLazy(
  () => import('components/pages/admin/adminProjectExport')
);
const AdminBuilderExport = RetryLazy(
  () => import('components/pages/admin/adminBuilderExport')
);
const AdminProjectImport = RetryLazy(
  () => import('components/pages/admin/adminProjectImport')
);
const AdminBuilderImport = RetryLazy(
  () => import('components/pages/admin/adminBuilderImport')
);
const AdminOrganizationEditPage = RetryLazy(
  () => import('components/pages/admin/adminOrganizationEdit')
);
const AdminBackendListPage = RetryLazy(
  () => import('components/pages/admin/adminBackendList')
);
const ChartPreview = RetryLazy(() => import('components/pages/chartPreview'));

// 初期化
const cookies = new Cookies();

if (
  import.meta.env.VITE_ENV === 'production' ||
  import.meta.env.VITE_ENV === 'staging'
) {
  Sentry.init({
    dsn: 'https://4f68b06e91554c78b4e55f2809327ab7@o165767.ingest.sentry.io/1237809',
    release: import.meta.env.VITE_SENTRY_RELEASE,
    ignoreErrors: [
      'ResizeObserver',
      'Firebase',
      'Network Error',
      'AxiosError',
      'CanceledError',
      'HTTP Client Error'
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    integrations: [Sentry.httpClientIntegration(), Sentry.replayIntegration()]
  });
} /* else {
const whyDidYouRender = require('@welldone-software/why-did-you-render');
whyDidYouRender(React, {
  logOwnerReasons: true,
  trackAllPureComponents: false,
  trackHooks: true
});
}*/

function initCookie() {
  if (
    cookies.get(clientIdName) == undefined ||
    cookies.get(clientIdName) === ''
  ) {
    cookies.set(clientIdName, GenerateId(), { path: '/' });
  }
}

// cookie name
initCookie();

// メイン画面のrender
const App = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <ErrorBoundary>
        <Provider store={store}>
          <GlobalLoaderProvider>
            <ConfirmationServiceProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="container">
                  <AuthProvider>
                    <Router history={history}>
                      <React.Suspense fallback={<div />}>
                        <Switch>
                          <PrivateRoute
                            path="/"
                            exact={true}
                            component={() => <Redirect to="/projects" />}
                          />
                          <PrivateRoute
                            path="/info"
                            exact={true}
                            component={InfoPage}
                          />
                          <PrivateRoute
                            path="/manual"
                            exact={true}
                            component={ManualPage}
                          />
                          <NotAuthedRoute
                            exact={true}
                            path="/login"
                            component={Login}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/user"
                            component={EditUser}
                          />
                          <PrivateRoute
                            exact={true}
                            path={['/projects', '/projects/f/:id']}
                            component={ProjectList}
                            refreshOnSamePage={true}
                          />
                          <PrivateRoute
                            exact={true}
                            path={['/datasources', '/datasources/f/:id']}
                            component={DatasourceList}
                            refreshOnSamePage={true}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/connections"
                            component={ConnectionList}
                            refreshOnSamePage={true}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/exports"
                            component={ExportListPage}
                            refreshOnSamePage={true}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/datasources/new"
                            component={NewDatasourceListPage}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/datasources/new/:type"
                            component={({ match }) => (
                              <NewDatasourceV3
                                datasourceType={match.params.type}
                              />
                            )}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/datasources_v2/new/:type"
                            component={({ match }) => (
                              <NewDatasourceV2
                                datasourceType={match.params.type}
                              />
                            )}
                          />
                          <ProtectedRoute
                            exact={true}
                            path="/datasources_v2/:uuid"
                            component={({ match }) => {
                              return (
                                <EditDatasourceV2 uuid={match.params.uuid} />
                              );
                            }}
                          />
                          <ProtectedRoute
                            exact={true}
                            path="/datasources/:uuid"
                            component={({ match }) => {
                              return (
                                <EditDatasourceV3 uuid={match.params.uuid} />
                              );
                            }}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/connections/new"
                            component={NewConnectionListPage}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/connections/new/:type"
                            component={({ match }) => (
                              <NewConnectionPage
                                connectionType={match.params.type}
                              />
                            )}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/connections/:id"
                            component={({ match }) => (
                              <EditConnectionPage id={match.params.id} />
                            )}
                          />
                          <ProtectedRoute
                            exact={true}
                            path={[
                              '/projects/:id',
                              '/projects/:id/nodes/:node_id'
                            ]}
                            component={({ match }) => (
                              <ProjectView
                                projectId={match.params.id}
                                nodeId={match.params.node_id}
                              />
                            )}
                          />
                          <PrivateRoute
                            exact={true}
                            path={['/variables', '/variables/f/:id']}
                            component={VariableListPage}
                            refreshOnSamePage={true}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/variables/new"
                            component={NewVariable}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/variables/:id"
                            component={({ match }) => {
                              return <EditVariable id={match.params.id} />;
                            }}
                          />
                          <PrivateRoute
                            exact={true}
                            path={['/reports', '/reports/f/:id']}
                            component={ReportListPage}
                            refreshOnSamePage={true}
                          />
                          <ProtectedRoute
                            // レポートのパスワード認証
                            exact={true}
                            path="/reports/login/:report_id"
                            authNotRequired={() => true}
                            component={({ match }) => {
                              return (
                                <ReportLogin id={match.params.report_id} />
                              );
                            }}
                          />
                          <ProtectedRoute
                            exact={true}
                            path="/reports/:report_id"
                            authNotRequired={(props) =>
                              new URLSearchParams(props.location.search).has(
                                'token'
                              )
                            }
                            component={({ match, location }) => {
                              const qs = new URLSearchParams(location.search);
                              if (qs.get('token')) {
                                return (
                                  <ReportView
                                    id={match.params.report_id}
                                    editable={false}
                                    token={qs.get('token')}
                                  />
                                );
                              }
                              return (
                                <ReportView
                                  id={match.params.report_id}
                                  editable={true}
                                />
                              );
                            }}
                          />
                          {/* ダッシュボードのスクショ用 */}
                          <Route
                            exact={true}
                            path="/_internal/report_preview/:id"
                            render={({ match, location }) => {
                              const qs = new URLSearchParams(location.search);
                              if (qs.get('token')) {
                                return (
                                  <ReportPreview
                                    id={match.params.id}
                                    token={qs.get('token')}
                                  />
                                );
                              }
                              return <Redirect to="/" />;
                            }}
                          />
                          <PrivateRoute
                            exact={true}
                            path={['/builders', '/builders/f/:id']}
                            component={BuilderListPage}
                            refreshOnSamePage={true}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/builders/new"
                            component={NewBuilderPage}
                          />
                          <ProtectedRoute
                            exact={true}
                            path="/builders/:project_id"
                            component={({ match }) => (
                              <BuilderView
                                projectId={match.params.project_id}
                              />
                            )}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/builders/:project_id/connection"
                            component={({ match }) => (
                              <EditBuilderConnectionPage
                                projectId={match.params.project_id}
                              />
                            )}
                          />
                          <PrivateRoute
                            exact={true}
                            path={['/schedules/new', '/schedules/:uuid']}
                            component={({ match }) => {
                              return (
                                <ScheduleEditPage
                                  uuid={match.params.uuid}
                                  key={match.params.uuid}
                                />
                              );
                            }}
                          />
                          <PrivateRoute
                            exact={true}
                            path={['/schedules', '/schedules/f/:id']}
                            component={ScheduleListPage}
                          />
                          {/* 管理者用のページ */}
                          <AdminRoute
                            exact={true}
                            path="/admin/users"
                            component={AdminUserListPage}
                          />
                          <AdminRoute
                            exact={true}
                            path="/admin/storage_usage"
                            component={AdminStorageUsage}
                          />
                          <AdminRoute
                            exact={true}
                            path="/admin/audit_logs"
                            component={AdminAuditLogPage}
                          />
                          <AdminRoute
                            exact={true}
                            path="/admin/payment"
                            component={AdminEditPaymentPage}
                          />
                          <AdminRoute
                            exact={true}
                            path={['/admin/projects', '/admin/projects/f/:id']}
                            component={AdminProjectListPage}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path={[
                              '/admin/datasources',
                              '/admin/datasources/f/:id'
                            ]}
                            component={AdminDatasourceListPage}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path={['/admin/builders', '/admin/builders/f/:id']}
                            component={AdminBuilderListPage}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path="/admin/connections"
                            component={AdminConnectionListPage}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path={['/admin/variables/:id']}
                            component={({ match }) => (
                              <EditVariable id={match.params.id} admin={true} />
                            )}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path={[
                              '/admin/variables',
                              '/admin/variables/f/:id'
                            ]}
                            component={AdminVariableListPage}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path={['/admin/reports', '/admin/reports/f/:id']}
                            component={AdminReportListPage}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path={[
                              '/admin/schedules',
                              '/admin/schedules/f/:id'
                            ]}
                            component={AdminScheduleListPage}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path={[
                              '/admin/notifications',
                              '/admin/notifications/f/:id'
                            ]}
                            component={AdminNotificationListPage}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path={[
                              '/admin/notification_dsts',
                              '/admin/notification_dsts/f/:id'
                            ]}
                            component={AdminNotificationDstsListPage}
                            refreshOnSamePage={true}
                          />
                          <AdminRoute
                            exact={true}
                            path={['/admin/user_groups']}
                            component={AdminUserGroupListPage}
                            refreshOnSamePage={true}
                          />
                          <NehanAdminRoute
                            exact={true}
                            path={['/admin/organizations']}
                            component={AdminOrganizationListPage}
                            refreshOnSamePage={true}
                          />
                          <NehanAdminRoute
                            exact={true}
                            path="/admin/organizations/new"
                            component={AdminOrganizationEditPage}
                          />
                          <NehanAdminRoute
                            exact={true}
                            path="/admin/organizations/:id"
                            component={({ match }) => (
                              <AdminOrganizationEditPage id={match.params.id} />
                            )}
                          />
                          <NehanAdminRoute
                            exact={true}
                            path="/admin/backends"
                            component={AdminBackendListPage}
                          />
                          <NehanAdminRoute
                            exact={true}
                            path="/admin/project_import"
                            component={AdminProjectImport}
                          />
                          <NehanAdminRoute
                            exact={true}
                            path="/admin/builder_import"
                            component={AdminBuilderImport}
                          />
                          <NehanAdminRoute
                            exact={true}
                            path="/admin/project_export"
                            component={AdminProjectExport}
                          />
                          <NehanAdminRoute
                            exact={true}
                            path="/admin/builder_export"
                            component={AdminBuilderExport}
                          />
                          <ProtectedRoute
                            path={[
                              '/projects/:project_id/nodes/:node_id/visualize',
                              '/projects/:project_id/nodes/:node_id/visualize/:chart_id'
                            ]}
                            exact={true}
                            component={({ match }) => (
                              <VisualizeViewV3
                                id={match.params.chart_id}
                                projectId={match.params.project_id}
                                nodeId={match.params.node_id}
                              />
                            )}
                          />
                          {/* チャートのスクショ用 */}
                          <Route
                            exact={true}
                            path="/_internal/charts/:id"
                            render={({ match, location }) => {
                              const qs = new URLSearchParams(location.search);
                              if (qs.get('token')) {
                                return (
                                  <ChartPreview
                                    chartId={match.params.id}
                                    token={qs.get('token')}
                                  />
                                );
                              }

                              return <Redirect to="/" />;
                            }}
                          />
                          <ProtectedRoute
                            path="/projects/:project_id/nodes/:node_id/supplement/:supplement_name"
                            exact={true}
                            component={({ match }) => (
                              <Supplement
                                projectId={match.params.project_id}
                                nodeId={match.params.node_id}
                                supplementName={match.params.supplement_name}
                              />
                            )}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/oauth/callback/:type"
                            component={({ match }) => (
                              <OauthCallback type={match.params.type} />
                            )}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/notification_dsts/select_type"
                            component={NotificationDstSelectType}
                            refreshOnSamePage={true}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/notification_dsts/new/:type"
                            component={({ match }) => (
                              <EditNotificationDst type={match.params.type} />
                            )}
                            refreshOnSamePage={true}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/notification_dsts/:uuid"
                            component={({ match }) => (
                              <EditNotificationDst uuid={match.params.uuid} />
                            )}
                            refreshOnSamePage={true}
                          />
                          <PrivateRoute
                            exact={true}
                            path="/notification_dsts"
                            component={NotificationDstList}
                            refreshOnSamePage={true}
                          />

                          <PrivateRoute
                            exact={true}
                            path={[
                              '/notifications/new',
                              '/notifications/:uuid'
                            ]}
                            component={({ match }) => {
                              return (
                                <NotificationEditPage
                                  uuid={match.params.uuid}
                                  key={match.params.uuid}
                                />
                              );
                            }}
                          />
                          <PrivateRoute
                            exact={true}
                            path={['/notifications', '/notifications/f/:id']}
                            component={NotificationList}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      </React.Suspense>
                    </Router>
                  </AuthProvider>
                </div>
              </LocalizationProvider>
            </ConfirmationServiceProvider>
          </GlobalLoaderProvider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  </StyledEngineProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
