import React, { createContext, useContext } from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import { getHasResourcePermission } from 'libs/api';
import { AccessLevel } from 'libs/accessLevel';

export const AccessControlContext = createContext<{ viewerDisabled: boolean }>({
  viewerDisabled: false
});

export const AccessControlProvider = ({ children, viewerDisabled }) => {
  return (
    <AccessControlContext.Provider value={{ viewerDisabled }}>
      {children}
    </AccessControlContext.Provider>
  );
};

export const useAccessControlContext = () => {
  return useContext(AccessControlContext);
};

export const viewerDisabledTooltipTitle = '閲覧専用のため、操作できません';

export const ViewerDisabledTooltip: React.FC<
  Omit<TooltipProps, 'title'> & { open?: boolean }
> = ({ children, open, ...props }) => {
  const title = open ? viewerDisabledTooltipTitle : '';

  return (
    <Tooltip {...props} title={title}>
      <span>{children}</span>
    </Tooltip>
  );
};

export const getHasCreateResoucePermission = async (
  folderId: string | undefined
): Promise<boolean> => {
  // フォルダがトップならok
  if (!folderId) {
    return true;
  }

  // フォルダの開発者権限があればok
  const {
    data: { has_permission }
  } = await getHasResourcePermission(folderId, 'folder', AccessLevel.Developer);
  return has_permission;
};
