import * as React from 'react';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  Divider,
  IconButton,
  Link,
  LinkProps,
  Tooltip,
  Typography
} from '@mui/material';
import { Spinner } from 'components/LoadingSpinner';
import {
  Dashboard,
  FolderOpen,
  NotificationsActive,
  People,
  Person,
  SettingsInputComponent,
  TextFormat
} from '@mui/icons-material';
import clsx from 'clsx';
import {
  BaseItem,
  BuilderItem,
  ConnectionItem,
  Creator,
  DatasourceItem,
  DependencyItems,
  ExportItem,
  FolderItem,
  ListItem,
  ListItemType,
  NotificationDstItem,
  NotificationItem,
  ReportItem,
  ScheduleItem,
  VariableItem
} from 'models/dependency';
import { AccessLevel, AccessLevelString } from 'libs/accessLevel';
import { getDependency } from 'libs/api';
import { createLink } from 'reducers/datasource';
import { getDatasourceExpression } from 'libs/datasources';
import SvgIcSqlBuilder from 'components/icons/IcSqlBuilder';
import SvgIcDashboard from 'components/icons/IcDashboard';
import { formatTimestamp, getItemID, typeToText } from 'ui/listViewTableCell';
import { Path } from 'models/folder';
import { RelationshipsDialog } from 'components/relationships/relationshipsDialog';
import IcRelationships from 'components/icons/IcRelationships';
import { IconSchedule } from 'components/icons/IcSchedule';
import { Avatar } from 'ui/common/avatar';
import { SvgIcProject } from 'components/icons/IcProject';
import { SvgIcDatasource } from 'components/icons/IcDatasource';
import { IconConnection } from 'components/icons/IcConnection';
import { IconExport } from 'components/icons/IcExport';
import { Tab, Tabs } from './Tab';

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      height: '100%',
      width: '100%',
      boxShadow: '10px 0 3px -3px rgba(0, 0, 0, 0.1) inset',
      fontFamily: 'NotoSansCJKjp',
      fontSize: 14,
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '2.14',
      letterSpacing: 'normal'
    },
    tabBar: {
      height: 64,
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#f9f9f9',
      width: '100%',
      boxShadow: 'rgba(0, 0, 0, 0.06) 10px 0px 3px -3px inset'
    },
    name: {
      minHeight: 27,
      fontSize: 18,
      fontWeight: 'bold',
      lineHeight: 'normal',
      color: '#000000'
    },
    infoArea: {
      marginTop: 11,
      borderRadius: 6,
      backgroundColor: '#eeeeee'
    },
    shareInfo: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 60
    },
    tabContainer: {
      // marginLeft: 19,
      width: '100%'
    },
    previewTitle: {
      marginLeft: 8,
      marginRight: 15,
      paddingTop: 15,
      paddingBottom: 15,
      justifyContent: 'space-between'
    },
    previewCenter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    previewItem: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 0px'
    },
    grey: {
      color: '#9f9f9f'
    },
    black: {
      color: '#000000'
    },
    showAllLink: {
      fontSize: 14,
      fontWeight: 500
    },
    tabsIndicator: {
      display: 'none'
    },
    content: {
      marginTop: 36,
      paddingLeft: 21,
      paddingRight: 14,
      overflowY: 'auto',
      height: 'calc(100vh - 180px)'
    }
  })
);

const tabIcon: { [name: string]: React.ReactElement } = {
  projects: <SvgIcProject />,
  builders: <SvgIcSqlBuilder />,
  datasources: <SvgIcDatasource />,
  connections: <IconConnection />,
  exports: <IconExport />,
  schedules: <IconSchedule />,
  dashboards: <Dashboard />,
  reports: <SvgIcDashboard />,
  folder_items: <FolderOpen />,
  variables: <TextFormat />,
  notifications: <NotificationsActive />,
  notification_dsts: <SettingsInputComponent />
};

interface ShareInfoProps {
  people: number;
  accessLevel: AccessLevel;
}

export const ShareInfo: React.FC<ShareInfoProps> = ({
  people,
  accessLevel
}) => {
  const classes = useStyles();
  const text = `${people}人`;
  return (
    <div className={clsx(classes.infoArea, classes.shareInfo)}>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
        <People />
        <span style={{ marginLeft: 4, fontWeight: 'bold' }}>共有</span>
        <span style={{ marginLeft: 10 }}>{text}</span>
      </div>
      <div style={{ marginRight: 15 }}>
        <span className={classes.grey}>あなたの権限: </span>
        <span style={{ fontWeight: 'bold' }}>
          {AccessLevelString(accessLevel)}
        </span>
      </div>
    </div>
  );
};

interface CreatorInfoProps {
  creator: Creator;
}

export const CreatorInfo: React.FC<CreatorInfoProps> = ({ creator }) => {
  const classes = useStyles();
  let displayName = creator.last_name
    ? creator.last_name + ' ' + creator.first_name
    : '';
  if (!displayName) {
    displayName = creator.email;
  }
  if (creator.is_deleted) {
    displayName = '削除されたユーザー';
  }

  return (
    <div className={clsx(classes.infoArea, classes.shareInfo)}>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
        <Person />
        <span style={{ marginLeft: 4, fontWeight: 'bold' }}>作成者</span>
        <span style={{ marginLeft: 10 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 4
            }}
          >
            {!creator.is_deleted && <Avatar label={creator.last_name} />}
            <Typography
              style={{ fontSize: '14px' }}
              color={creator.is_deleted ? 'error' : 'initial'}
            >
              {displayName}
            </Typography>
          </div>
        </span>
      </div>
    </div>
  );
};

export const BaseInfo: React.FC<{
  icon: React.ReactNode;
  title: string;
  text: string;
}> = ({ icon, title, text }) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.infoArea, classes.shareInfo)}>
      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 8 }}>
        {icon}
        <strong style={{ marginLeft: 4 }}>{title}</strong>
        <span style={{ marginLeft: 10 }}>{text}</span>
      </div>
    </div>
  );
};

interface DetailPreviewProps {
  type: ListItemType;
  items: ListItem[];
  index: number;
  onClick?: (index: number) => void;
}

const DetailPreview: React.FC<DetailPreviewProps> = ({
  type,
  items,
  index,
  onClick
}) => {
  const classes = useStyles();
  const length = items.length;
  const text = typeToText[type];

  const onClickLink = React.useCallback(() => {
    if (onClick) {
      onClick(index + 1);
    }
  }, [index]);

  const previewItems = onClick != undefined ? items.slice(0, 3) : items;

  return (
    <div className={classes.infoArea}>
      <div className={clsx(classes.previewTitle, classes.previewCenter)}>
        <div className={classes.previewCenter}>
          {tabIcon[type]}
          <div style={{ marginLeft: 8 }} className={classes.black}>
            {text}
          </div>
          <div style={{ marginLeft: 14 }} className={classes.black}>
            {length}
          </div>
        </div>
        {onClick != undefined && length > 3 && (
          <Link
            component="button"
            variant="body2"
            color="inherit"
            onClick={onClickLink}
            underline="hover"
          >
            <div className={classes.showAllLink}>すべて見る &gt;</div>
          </Link>
        )}
      </div>
      <PreviewItems type={type} items={previewItems} />
    </div>
  );
};

const PreviewItems: React.FC<{ type: ListItemType; items: ListItem[] }> = ({
  type,
  items
}) => {
  const classes = useStyles();
  const content = (item: ListItem) => {
    switch (type) {
      case 'projects':
        return <PreviewProject item={item as BaseItem} />;
      case 'builders':
        return <PreviewBuilder item={item as BuilderItem} />;
      case 'connections':
        return <PreviewConnection item={item as ConnectionItem} />;
      case 'datasources':
        return <PreviewDatasource item={item as DatasourceItem} />;
      case 'schedules':
        return <PreviewSchedule item={item as ScheduleItem} />;
      case 'exports':
        return <PreviewExport item={item as ExportItem} />;
      case 'reports':
        return <PreviewReport item={item as ReportItem} />;
      case 'folder_items':
        return <PreviewFolder item={item as FolderItem} />;
      case 'variables':
        return <PreviewVariable item={item as VariableItem} />;
      case 'notifications':
        return <PreviewNotification item={item as NotificationItem} />;
      case 'notification_dsts':
        return <PreviewNotificationDst item={item as NotificationDstItem} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginLeft: 40, marginRight: 15 }}>
      {items.map((item, i) => {
        const id = getItemID(type, item);
        return (
          <div key={`${id}-${i}`}>
            <Divider />
            <div className={classes.previewItem}>{content(item)}</div>
          </div>
        );
      })}
    </div>
  );
};

const DeletedText: React.FC<{}> = () => {
  return (
    <Typography variant="inherit" color="error">
      削除済みです
    </Typography>
  );
};

const DisabledText: React.FC<{}> = () => {
  return (
    <Typography variant="inherit" color="error">
      権限がありません
    </Typography>
  );
};

const DefaultText: React.FC<{
  text: string;
  linkProps: LinkProps;
  imgProps?: React.ImgHTMLAttributes<HTMLImageElement>;
}> = ({ text, linkProps, imgProps }) => {
  return (
    <Link color="initial" underline="hover" {...linkProps}>
      {imgProps && (
        <img
          width="20"
          style={{ verticalAlign: 'sub', marginRight: 5 }}
          {...imgProps}
        />
      )}
      {text}
    </Link>
  );
};

const PreviewProject: React.FC<{ item: BaseItem }> = ({ item }) => {
  const classes = useStyles();

  if (item.access_level < AccessLevel.Viewer) {
    return <DisabledText />;
  }

  return (
    <>
      <DefaultText
        text={item.name}
        linkProps={{
          href: `/projects/${item.uuid}`
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.updated_at)}</div>
    </>
  );
};

const PreviewBuilder: React.FC<{ item: BuilderItem }> = ({ item }) => {
  const classes = useStyles();
  const expr = getDatasourceExpression(item.connection_type);

  if (item.access_level < AccessLevel.Viewer) {
    return <DisabledText />;
  }

  return (
    <>
      <DefaultText
        text={item.name}
        linkProps={{
          href: `/builders/${item.uuid}`
        }}
        imgProps={{
          alt: item.connection_type,
          src: expr.logoPath
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.updated_at)}</div>
    </>
  );
};

const PreviewConnection: React.FC<{ item: ConnectionItem }> = ({ item }) => {
  const classes = useStyles();
  const expr = getDatasourceExpression(item.type);
  if (item.is_deleted) {
    return <DeletedText />;
  }

  if (item.access_level < AccessLevel.Viewer) {
    return <DisabledText />;
  }

  return (
    <>
      <DefaultText
        text={item.name}
        linkProps={{
          href: `/connections/${item.uuid}`
        }}
        imgProps={{
          alt: item.type,
          src: expr.logoPath
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.updated_at)}</div>
    </>
  );
};

const PreviewSchedule: React.FC<{ item: ScheduleItem }> = ({ item }) => {
  const classes = useStyles();

  if (item.is_deleted) {
    return <DeletedText />;
  }

  if (item.access_level < AccessLevel.Viewer) {
    return <DisabledText />;
  }

  return (
    <>
      <DefaultText
        text={item.name}
        linkProps={{
          href: `/schedules/${item.uuid}`
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.updated_at)}</div>
    </>
  );
};

const PreviewNotification: React.FC<{ item: NotificationItem }> = ({
  item
}) => {
  const classes = useStyles();
  return (
    <>
      <DefaultText
        text={item.name}
        linkProps={{
          href: `/notifications/${item.uuid}`
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.updated_at)}</div>
    </>
  );
};

const PreviewNotificationDst: React.FC<{ item: NotificationDstItem }> = ({
  item
}) => {
  const classes = useStyles();

  if (item.is_deleted) {
    return <DeletedText />;
  }

  if (item.access_level < AccessLevel.Viewer) {
    return <DisabledText />;
  }

  return (
    <>
      <DefaultText
        text={item.name}
        linkProps={{
          href: `/notification_dsts/${item.uuid}`
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.updated_at)}</div>
    </>
  );
};

const PreviewExport: React.FC<{ item: ExportItem }> = ({ item }) => {
  const classes = useStyles();
  const expr = getDatasourceExpression(item.type);
  return (
    <>
      <DefaultText
        text={item.destination}
        linkProps={{
          href: `/projects/${item.project_uuid}/nodes/${item.uuid}`
        }}
        imgProps={{
          alt: item.type,
          src: expr.logoPath
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.ended_at)}</div>
    </>
  );
};

const PreviewReport: React.FC<{ item: ReportItem }> = ({ item }) => {
  const classes = useStyles();
  return (
    <>
      <DefaultText
        text={item.name}
        linkProps={{
          href: `/reports/${item.uuid}`
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.updated_at)}</div>
    </>
  );
};

const PreviewFolder: React.FC<{ item: FolderItem }> = ({ item }) => {
  const classes = useStyles();
  const href = item.is_folder
    ? `/${item.type}/f/${item.uuid}`
    : item.type === 'datasources'
      ? createLink(item.datasource_type, item.uuid)
      : `/${item.type}/${item.uuid}`;
  return (
    <>
      <Link
        color="inherit"
        href={href}
        style={{ display: 'inline-flex' }}
        underline="hover"
      >
        {item.is_folder && <FolderOpen />}
        {item.name}
      </Link>
      <div className={classes.grey}>
        {item.is_folder ? '-' : formatTimestamp(item.updated_at)}
      </div>
    </>
  );
};

const PreviewDatasource: React.FC<{ item: DatasourceItem }> = ({ item }) => {
  const classes = useStyles();
  const expr = getDatasourceExpression(item.type);
  return (
    <>
      <DefaultText
        text={item.name}
        linkProps={{
          href: createLink(item.type, item.id)
        }}
        imgProps={{
          alt: item.type,
          src: expr.logoPath
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.updated_at)}</div>
    </>
  );
};

const PreviewVariable: React.FC<{ item: VariableItem }> = ({ item }) => {
  const classes = useStyles();
  return (
    <>
      <DefaultText
        text={item.name}
        linkProps={{
          href: `/variables/${item.uuid}`
        }}
      />
      <div className={classes.grey}>{formatTimestamp(item.updated_at)}</div>
    </>
  );
};

const ShowRelationshipsButton: React.VFC<{ id: string; type: string }> = ({
  id,
  type
}) => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Tooltip title="系譜">
        <IconButton onClick={onOpen} size="large">
          <IcRelationships height={24} width={24} />
        </IconButton>
      </Tooltip>
      <RelationshipsDialog id={id} type={type} open={open} onClose={onClose} />
    </>
  );
};

interface DetailPaneProps {
  type: ListItemType;
  item?: ListItem;
  setPaths?: (paths: Path[]) => void;
}

const tabsList: { [name: string]: ListItemType[] } = {
  projects: [
    'datasources',
    'builders',
    'connections',
    'reports',
    'exports',
    'schedules',
    'variables'
  ],
  builders: ['datasources', 'projects', 'connections', 'variables'],
  datasources: [
    'projects',
    'builders',
    'connections',
    'schedules',
    'variables'
  ],
  connections: ['datasources', 'projects', 'builders'],
  reports: ['projects', 'schedules'],
  folders: ['folder_items'],
  variables: ['projects', 'builders', 'datasources'],
  notification_dsts: ['schedules'],
  notifications: ['notification_dsts', 'schedules']
};

function typeToRelationshipsType(type: string): string {
  switch (type) {
    case 'projects':
      return 'project';
    case 'builders':
      return 'builder';
    case 'datasources':
      return 'datasource';
    case 'reports':
      return 'report';
    default:
      return type;
  }
}

const DetailPane: React.FC<DetailPaneProps> = ({ type, item, setPaths }) => {
  const [tabIndex, setTabIndex] = React.useState<number>(0);
  const [dependencies, setDependency] = React.useState<{
    loaded: boolean;
    items: DependencyItems;
  }>({
    loaded: false,
    items: { status: 0, people: 0, paths: [] }
  });
  const classes = useStyles();

  React.useEffect(() => {
    let unmounted = false;
    const f = async () => {
      if (item == undefined) {
        return;
      }

      if (!unmounted) {
        setTabIndex(0);
        setDependency({
          loaded: false,
          items: { status: 0, people: 0, paths: [] }
        });
        try {
          const { data } = await getDependency({
            type,
            resourceID: getItemID(type, item),
            isFolder: item?.is_folder ?? false
          });
          setDependency({ loaded: true, items: data });
          setPaths && setPaths(data.paths);
        } catch {}
      }
    };
    f();
    return () => {
      unmounted = true;
    };
  }, [type, item, setPaths]);

  const tabs = item?.is_folder ? tabsList.folders : tabsList[type];
  const onChangeTab = React.useCallback((_, newValue) => {
    setTabIndex(newValue);
  }, []);

  const content = () => {
    if (item == undefined) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          関連しているnehanコンテンツが表示されます
        </div>
      );
    }

    if (!dependencies.loaded) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner size={24} />
        </div>
      );
    }

    if (tabIndex > 0) {
      const tabType = tabs[tabIndex - 1];
      return (
        <DetailPreview
          type={tabType}
          index={tabIndex}
          items={dependencies.items[tabType] || []}
        />
      );
    }

    const summary = () => {
      switch (type) {
        case 'projects':
        case 'builders':
        case 'connections':
        case 'datasources':
        case 'reports':
        case 'variables':
        case 'notification_dsts':
        case 'notifications': {
          const pj = item as
            | BaseItem
            | BuilderItem
            | ConnectionItem
            | DatasourceItem
            | ReportItem
            | VariableItem
            | NotificationDstItem
            | NotificationItem;
          return (
            <>
              <div className={classes.name}>
                {pj.name}
                {['projects', 'builders', 'datasources', 'reports'].includes(
                  type
                ) &&
                  !pj.is_folder && (
                    <ShowRelationshipsButton
                      id={pj.uuid}
                      type={typeToRelationshipsType(type)}
                    />
                  )}
              </div>
              <ShareInfo
                people={dependencies.items.people}
                accessLevel={pj.access_level}
              />
              {dependencies.items.creator && (
                <CreatorInfo creator={dependencies.items.creator} />
              )}
            </>
          );
        }
      }
    };

    return (
      <>
        {summary()}
        {tabs.map((tab, i) => {
          return (
            <DetailPreview
              key={`${tab}-${i}`}
              type={tab}
              index={i}
              items={dependencies.items[tab] || []}
              onClick={setTabIndex}
            />
          );
        })}
      </>
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.tabBar}>
        <div className={classes.tabContainer}>
          <Tabs
            variant="scrollable"
            scrollButtons
            value={tabIndex}
            onChange={onChangeTab}
            allowScrollButtonsMobile
          >
            <Tab label="関連" />
            {tabs.map((tab, i) => {
              return (
                <Tab
                  key={`${tab}-${i}`}
                  icon={tabIcon[tab]}
                  sx={{ minWidth: 60 }}
                />
              );
            })}
          </Tabs>
        </div>
      </div>
      <div className={classes.content}>{content()}</div>
    </div>
  );
};

export default DetailPane;
