import { AccessLevel } from 'libs/accessLevel';
import { _delete, _get, _post, _put } from 'libs/api/base';
import { AccessLevelsRow } from 'models/access_level';
import { ResourceType } from 'models/resource';

export function getResourceAccessLevel(
  resource_id: string,
  resource_type: string
) {
  return _post<{ access_level: number }>(`/access_level`, {
    resource_id,
    resource_type
  });
}

export function getResourceAccessLevels(
  resource_ids: string[],
  resource_type: string
) {
  return _post<AccessLevelsRow[]>(`/access_levels`, {
    resource_ids,
    resource_type
  });
}

export function getHasResourcePermission(
  resource_id: string,
  resource_type: ResourceType,
  access_level: AccessLevel
) {
  return _post<{ has_permission: boolean }>(`/has_permission`, {
    resource_id,
    resource_type,
    access_level
  });
}
