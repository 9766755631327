import * as React from 'react';

import { Dialog, DialogContent, Typography } from '@mui/material';

export const GlobalLoader: React.FC<{ open: boolean; message?: string }> = ({
  open,
  message = '処理中です'
}) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          backgroundColor: '#f0f0f0',
          width: '400px'
        }
      }}
    >
      <DialogContent>
        <div
          style={{
            display: 'table',
            margin: 'auto',
            height: '120px',
            padding: 0
          }}
        >
          <span
            style={{
              display: 'inline-block',
              height: '100%',
              verticalAlign: 'middle'
            }}
          />
          <img
            src="/images/loading_B.gif"
            style={{
              width: '60px',
              verticalAlign: 'middle',
              marginRight: '10px'
            }}
          />
          <Typography
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              textAlign: 'left',
              color: '#e2762a',
              fontWeight: 'bold'
            }}
          >
            {message}
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
};
