import { AccessLevel } from 'libs/accessLevel';
import { getResourceAccessLevel } from 'libs/api';
import { ResourceType } from 'models/resource';
import { useAsync } from 'react-use';
import { AsyncState } from 'react-use/lib/useAsync';

export const getAccessLevel = async (
  resourceId: string | undefined,
  resourceType: ResourceType,
  forceExecuteApi = false
): Promise<{ accessLevel: AccessLevel; exist: boolean }> => {
  // resourceIdが空の場合は新規作成とみなして管理者権限を与えているが、
  // 編集画面の場合はリソースが削除されているせいでresourceIdが空の場合があるので、
  // その時は forceExecuteApi = true にして強制的にAPIを叩き、権限がない or 削除されているかを確認する
  if (!forceExecuteApi) {
    // 新規の場合は管理者権限を与える
    if (resourceId == undefined || resourceId === '' || resourceId === 'new') {
      return { accessLevel: AccessLevel.Admin, exist: true };
    }
  }

  try {
    const {
      data: { access_level }
    } = await getResourceAccessLevel(resourceId || '', resourceType);
    return { accessLevel: access_level, exist: true };
  } catch (e) {
    return { accessLevel: AccessLevel.Disabled, exist: false };
  }
};

export const useAccessLevel = (
  resourceId: string | undefined,
  resourceType: ResourceType,
  forceExecuteApi = false
): AsyncState<{
  accessLevel: AccessLevel;
  exist: boolean;
}> => {
  const accessLevel = useAsync<
    () => Promise<{ accessLevel: AccessLevel; exist: boolean }>
  >(async () => {
    return await getAccessLevel(resourceId, resourceType, forceExecuteApi);
  }, [resourceId, resourceType]);

  return accessLevel;
};

// これが使われるのはオブジェクトの編集ページなので、そもそもexistを見る必要がない
export const useViewerDisabled = (
  resourceId: string | undefined,
  resourceType: ResourceType
): boolean => {
  const result = useAccessLevel(resourceId, resourceType);
  if (result.loading || result.error || !result.value) {
    return true;
  }
  return result.value.accessLevel < AccessLevel.Developer;
};

export const useDeletedAndDisabled = (
  resourceId: string | undefined,
  resourceType: ResourceType,
  forceExecuteApi = false
): { deleted: boolean; disabled: boolean; loading: boolean } => {
  const result = useAccessLevel(resourceId, resourceType, forceExecuteApi);
  return {
    deleted: !result.value?.exist,
    disabled:
      (result.value?.accessLevel || AccessLevel.Disabled) < AccessLevel.Viewer,
    loading: result.loading
  };
};
