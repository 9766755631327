import { NodeStatus } from 'models/graph';
import { orange } from '@mui/material/colors';

export interface NodeColorType {
  dark: string;
  light: string;
}
// Setting Node Color
export const NodeColor: {
  [key in NodeStatus]: NodeColorType;
} = {
  [NodeStatus.Temporary]: { dark: '#676a75', light: '#bdc0cc' },
  [NodeStatus.Copied]: { dark: '#676a75', light: '#bdc0cc' },
  [NodeStatus.Warning]: { dark: '#f3a95c', light: '#fff6ee' },
  [NodeStatus.EmptyOutput]: { dark: '#f3a95c', light: '#fff6ee' },
  [NodeStatus.Error]: { dark: '#df5d76', light: '#fff4f6' },
  [NodeStatus.Prospecting]: { dark: '#3399ff', light: '#3399ff' }, // TODO: 使ってない？
  [NodeStatus.Executing]: { dark: orange[700], light: orange[300] }, // TODO: 使ってない？
  [NodeStatus.Prospected]: { dark: '#4f9af8', light: '#edf5ff' },
  [NodeStatus.Executed]: { dark: '#7fce5d', light: '#f5fff1' }
};

export const NodeIconColor: {
  [key in NodeStatus]: NodeColorType;
} = {
  [NodeStatus.Temporary]: { dark: '#2e313c', light: '#676a75' },
  [NodeStatus.Copied]: { dark: '#2e313c', light: '#676a75' },
  [NodeStatus.Warning]: { dark: '#cc6701', light: '#f3a95c' },
  [NodeStatus.EmptyOutput]: { dark: '#cc6701', light: '#f3a95c' },
  [NodeStatus.Error]: { dark: '#9a3f4f', light: '#df5d76' },
  [NodeStatus.Prospecting]: { dark: '#3366cc', light: '#3399ff' }, // TODO: 使ってない？
  [NodeStatus.Executing]: { dark: '#339933', light: '#47cf73' }, // TODO: 使ってない？
  [NodeStatus.Prospected]: { dark: '#3d68c5', light: '#4f9af8' },
  [NodeStatus.Executed]: { dark: '#549d35', light: '#7fce5d' }
};
